import { h, Component } from 'preact';
import { Router } from 'preact-router'

import Header from './header';
import Footer from './footer';
import Home from '../components/home';
import Editorial from '../routes/editorial'
import Company from '../routes/company';
import History from '../routes/history'
import Operator from '../routes/operator'
import SustainableTransport from '../routes/sustainableTransport'
import ReadyForAction from '../routes/readyForAction'
import Certificates from '../routes/certificates'
import Contact from '../routes/contact'
import Clients from '../routes/clients'
import Team from '../routes/team'
import Map from '../routes/map'
import Agencies from '../routes/agencies'
import News from '../routes/news'
import Legal from '../routes/legal'
import CGV from '../routes/cgv'
import style from './style';

if (module.hot) {
	require('preact/debug');
}

export default class App extends Component {
	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.currentUrl = e.url;
	};

	render() {
		return (
			<div id="app">
			<link href="https://fonts.googleapis.com/css?family=Lato:400,700,900&amp;subset=latin-ext" rel="stylesheet" />
				<Header />
				<main>
					<Router onChange={this.handleRoute}>
						<Home path="/" />
						<Company path="/notre-entreprise" />
						<Editorial path='/editorial' />
						<History path='/historique' />
						<Operator path='/operateur-de-transport-combine' />
						<SustainableTransport path='/developpement-durable' />
						<ReadyForAction path='/toujours-mobilise' />
						<Certificates path='/certificats-economie-energie' />
						<Contact path='/contact' />
						<Clients path='/references' />
						<Team path='/equipe' />
						<Map path='/plan-de-transport' />
						<Agencies path='/agences' />
						<News path='/actualites/:newsIndex?' />
						<Legal path='/mentions-legales' />
						<CGV path='/conditions-generales-de-vente' />
					</Router>
				</main>
				<Footer />
			</div>
		);
	}
}
