import { h, Component } from 'preact'
import { Link } from 'preact-router/match'
import { route } from 'preact-router'
import style from './style'
import logo from '../../assets/logo-institutionnel.png'
import logo2x from '../../assets/logo-institutionnel@2x.png'
import menuIcon from '../../assets/ico-menu.svg'
import menuIconOpen from '../../assets/ico-menu-open.svg'

export default class Header extends Component {
  constructor (p) {
    super(p)
    this.setState({company: false, work: false, sustainable: false, agencies: false, mobileMenu: false})
  }

  renderCompanyMenu () {
    return (
      <div class={style.menu + ' ' + style.companyMenu} onMouseEnter={this.toggleMenu.bind(this, 'company', true, true)} onMouseLeave={this.toggleMenu.bind(this, 'company', false, false)}>
        <div class={style.item}><Link href='/notre-entreprise'>Notre entreprise</Link></div>
        <div class={style.item}><Link href='/editorial'>Éditorial (mot du PDG)</Link></div>
        <div class={style.item}><Link href='/historique'>L'historique en bref...</Link></div>
        <div class={style.item}><Link href='/references'>Nos références</Link></div>
        <div class={style.item}><Link href='/equipe'>L'équipe</Link></div>
      </div>
    )
  }

  renderWorkMenu () {
    return (
      <div class={style.menu + ' ' + style.workMenu} onMouseEnter={this.toggleMenu.bind(this, 'work', true, true)} onMouseLeave={this.toggleMenu.bind(this, 'work', false, false)}>
        <div class={style.item}><Link href='/operateur-de-transport-combine'>Opérateur de transport combiné</Link></div>
        <div class={style.item}><Link href='/plan-de-transport'>Plan de transport</Link></div>
      </div>
    )
  }

  renderSustainableMenu () {
    return (
      <div class={style.menu + ' ' + style.sustainableMenu} onMouseEnter={this.toggleMenu.bind(this, 'sustainable', true, true)} onMouseLeave={this.toggleMenu.bind(this, 'sustainable', false, false)}>
        <div class={style.item}><Link href='/developpement-durable'>Transport et développement durable</Link></div>
        <div class={style.item}><Link href='/toujours-mobilise'>Froidcombi toujours mobilisé</Link></div>
        <div class={style.item}><Link href='/certificats-economie-energie'>Certificats d'économie d'énergie</Link></div>
      </div>
    )
  }

  toggleMenu (menu, active, preserve) {
    if (!active && this.state.preserve === menu) {
      this.setState({preserve: null})
      return
    }

    let s = {}
    s[menu] = active
    if (preserve) {
      s.preserve = menu
    }
    this.setState(s)
  }

  toggleMobileMenu (delay) {
    if (delay) {
      setTimeout(() => {
        this.setState({mobileMenu: !this.state.mobileMenu, company: false, work: false, sustainable: false})
      }, 50)
    } else {
      this.setState({mobileMenu: !this.state.mobileMenu, company: false, work: false, sustainable: false})
    }
  }

  toggleMobileMenuItem (item) {
    let s = {company: false, work: false, sustainable: false}
    s[item] = !this.state[item]
    this.setState(s)
  }

  render () {
    return (
      <header class={style.header}>
        <div class={style.content}>
          <img class={style.logo} src={logo} srcset={logo2x + ' 2x'} alt='Logo Froidcombi' onClick={route.bind(null, '/', true)}/>
          <nav class={style.links}>
            <div class={this.state.company ? style.active : ''} onClick={this.toggleMenu.bind(this, 'company', !this.state.company, false)} onMouseEnter={this.toggleMenu.bind(this, 'company', true, false)} onMouseLeave={() => { setTimeout(this.toggleMenu.bind(this, 'company', false, false), 50) }}>La société</div>
            <div class={this.state.work ? style.active : ''} onClick={this.toggleMenu.bind(this, 'work', !this.state.work, false)} onMouseEnter={this.toggleMenu.bind(this, 'work', true, false)} onMouseLeave={() => { setTimeout(this.toggleMenu.bind(this, 'work', false, false), 50) }}>Notre métier</div>
            <Link activeClassName={style.active} href='/agences' class={this.state.agencies ? style.active : ''} onMouseEnter={this.toggleMenu.bind(this, 'agencies', true, false)} onMouseLeave={() => { setTimeout(this.toggleMenu.bind(this, 'agencies', false, false), 50) }}>Les agences</Link>
            <div class={this.state.sustainable ? style.active : ''} onClick={this.toggleMenu.bind(this, 'sustainable', !this.state.sustainable, false)} onMouseEnter={this.toggleMenu.bind(this, 'sustainable', true, false)} onMouseLeave={() => { setTimeout(this.toggleMenu.bind(this, 'sustainable', false, false), 50) }}>Développement durable</div>
            <Link activeClassName={style.active} href='/actualites' class={this.state.news ? style.active : ''}  onMouseEnter={this.toggleMenu.bind(this, 'news', true, false)} onMouseLeave={() => { setTimeout(this.toggleMenu.bind(this, 'news', false, false), 50) }}>Actualités</Link>
            <Link activeClassName={style.active} href='/contact' class={this.state.contact ? style.active : ''} onMouseEnter={this.toggleMenu.bind(this, 'contact', true, false)} onMouseLeave={() => { setTimeout(this.toggleMenu.bind(this, 'contact', false, false), 50) }}>Contact</Link>
          </nav>
          <img class={style.icon} src={this.state.mobileMenu ? menuIconOpen : menuIcon} onClick={this.toggleMobileMenu.bind(this)} />

          {this.state.company && this.renderCompanyMenu()}
          {this.state.work && this.renderWorkMenu()}
          {this.state.sustainable && this.renderSustainableMenu()}
        </div>

        {
          <nav class={style.mobileMenu + (this.state.mobileMenu ? (' ' + style.open) : '')}>
            <div class={style.mobileMenuItem + ' ' + (this.state.company ? style.active : '')} onClick={this.toggleMobileMenuItem.bind(this, 'company')}>La société</div>
            {
              this.state.company && (
                <div class={style.mobileSubMenu}>
                  <Link href='/notre-entreprise' onClick={this.toggleMobileMenu.bind(this, true)}>Notre entreprise</Link>
                  <Link href='/editorial' onClick={this.toggleMobileMenu.bind(this, true)}>Éditorial (mot du PDG)</Link>
                  <Link href='/historique' onClick={this.toggleMobileMenu.bind(this, true)}>L'historique en bref...</Link>
                  <Link href='/references' onClick={this.toggleMobileMenu.bind(this, true)}>Nos références</Link>
                  <Link href='/equipe' onClick={this.toggleMobileMenu.bind(this, true)}>L'équipe</Link>
                </div>
              )
            }
            <div class={style.mobileMenuItem + ' ' + (this.state.work ? style.active : '')} onClick={this.toggleMobileMenuItem.bind(this, 'work')}>Notre métier</div>
            {
              this.state.work && (
                <div class={style.mobileSubMenu}>
                  <Link href='/operateur-de-transport-combine' onClick={this.toggleMobileMenu.bind(this, true)}>Opérateur de transport combiné</Link>
                  <Link href='/plan-de-transport' onClick={this.toggleMobileMenu.bind(this, true)}>Plan de transport</Link>
                </div>
              )
            }
            <Link href='/agences' class={style.mobileMenuItem + ' ' + (this.state.agencies ? style.active : '')} onClick={this.toggleMobileMenu.bind(this, true)}>Les agences</Link>
            <div class={style.mobileMenuItem + ' ' + (this.state.sustainable ? style.active : '')} onClick={this.toggleMobileMenuItem.bind(this, 'sustainable')}>Développement durable</div>
            {
              this.state.sustainable && (
                <div class={style.mobileSubMenu}>
                  <Link href='/developpement-durable' onClick={this.toggleMobileMenu.bind(this, true)}>Transport et développement durable</Link>
                  <Link href='/toujours-mobilise' onClick={this.toggleMobileMenu.bind(this, true)}>Froidcombi toujours mobilisé</Link>
                  <Link href='/certificats-economie-energie' onClick={this.toggleMobileMenu.bind(this, true)}>Certificats d'économie d'énergie</Link>
                </div>
              )
            }
            <Link href='/actualites' class={style.mobileMenuItem + ' ' + (this.state.news ? style.active : '')} onClick={this.toggleMobileMenu.bind(this, true)}>Actualités</Link>
            <Link href='/contact' class={style.mobileMenuItem + ' ' + (this.state.contact ? style.active : '')} onClick={this.toggleMobileMenu.bind(this, true)}>Contact</Link>
          </nav>
        }
      </header>
    )
  }
}
