import { h, Component } from 'preact'
import { Link } from 'preact-router/match'
import { route } from 'preact-router'
import style from './style'
import mail from '../../assets/mail.svg'
import logoArkilium from '../../assets/logo-blanc-arkilium.png'
import logoArkilium2x from '../../assets/logo-blanc-arkilium@2x.png'
import linkedin from '../../assets/ico-linkedin.svg'
import axios from 'axios'
import logo2x from '../../assets/logo-institutionnel@2x.png'

export default class Footer extends Component {
  componentDidMount () {
		axios.get('https://froidcombi.fr/api/infos').then((infos) => {
      this.setState({infos: infos.data})
    })
	}

  render () {
    const data = {
      "@context": "http://schema.org",
      "@type": "Organization",
      "url": "https://froidcombi.fr",
      "logo": "https://froidcombi.fr" + logo2x,
      "name": "Froidcombi"
    }

    if (!this.state.infos) return (
      <script type="application/ld+json">
        {JSON.stringify(data)}
      </script>
    )

    data.contactPoint = {
      "@type": "ContactPoint",
      "contactType": "sales",
      "telephone": this.state.infos.phone
    }

    return (
      <footer class={style.footer}>
        <div class={style.content}>
          <div>
            ©2018&nbsp;<span class={style.white + ' ' + style.bold}>SA Froidcombi</span>
            <div><Link class={style.white} href='/mentions-legales'>Mentions légales et conditions d'utilisation</Link></div>
            <div><Link class={style.white} href='/conditions-generales-de-vente'>Conditions générales de vente</Link></div>
            <a href='https://fr.linkedin.com/company/froidcombi' target='_blank'><img class={style.linkedin} src={linkedin} /></a>
          </div>
          <div class={style.separator} />
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div class={style.phone}>
              <img src={mail} />
            </div>
            <div class={style.phoneDetails}>
              <div>Tél. : <span class={style.white}>{this.state.infos.phone}</span></div>
              <div>Fax : <span class={style.white}>{this.state.infos.fax}</span></div>
              <div>E-mail :&nbsp;<span class={style.white}>{this.state.infos.email}</span></div>
              <div class={style.white}>
                <div>Allée des jardiniers - ZA du Barret</div>
                <div>13160 Châteaurenard</div>
              </div>
            </div>
          </div>
          <div class={style.separator} />
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div class={style.hSeparator} />
            <a href='/notre-entreprise' class={style.link}>Notre entreprise</a>
            <a href='/editorial' class={style.link}>Éditorial</a>
            <a href='/historique' class={style.link}>L'historique en bref...</a>
            <a href='/references' class={style.link}>Nos références</a>
          </div>
          <div>
            <a href='/operateur-de-transport-combine' class={style.link}>Opérateur de transport combiné</a>
            <a href='/toujours-mobilise' class={style.link}>Froidcombi toujours mobilisé</a>
            <a href='/agences' class={style.link}>Les agences</a>
            <a href='/developpement-durable' class={style.link}>Transport et développement durable</a>
          </div>
        </div>
        <a href='http://www.arkilium.com' target='_blank'><img src={logoArkilium} srcset={logoArkilium2x + ' 2x'} class={style.logoArkilium} /></a>

        <script type="application/ld+json">
          {JSON.stringify(data)}
        </script>
      </footer>
    )
  }
}
