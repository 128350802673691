import { h, Component } from 'preact'
import style from './style'

export default class Button extends Component {
  render () {
    return (
      <div onClick={this.props.onClick} class={style.button + ((this.state.hover && !this.props.disabled) ? ' ' + style.hover : '') + (this.props.disabled ? ' ' + style.disab : '') + (this.props.large ? ' ' + style.large : '')} style={{width: (this.props.large ? '180px' : undefined)}}
      onMouseEnter={() => {if (!this.props.disabled) this.setState({hover: true})}}
      onMouseLeave={() => {if (!this.props.disabled) this.setState({hover: false})}}>
				{this.props.text}
      </div>
    )
  }
}
