import { h, Component } from 'preact'
import style from './style'

export default class Thumbnail extends Component {
  render () {
    return (
      <div class={style.thumbnail + (this.state.hover ? ' ' + style.hover : '')}
      onMouseEnter={() => this.setState({hover: true})}
      onMouseLeave={() => this.setState({hover: false})}
      onClick={this.props.onClick}>
				<div class={style.image} style={{backgroundImage: 'url(' + this.props.image + ')'}} />
        <div class={style.separator} />
        <div class={style.text}>{this.props.children}</div>
      </div>
    )
  }
}
