import { h, Component } from 'preact'
import { route } from 'preact-router'
import style from './style'
import Thumbnail from './thumbnail'
import Button from '../../components/button'
import Slider from '../../components/slider'
/* import banner1 from '../../assets/home-banner1.jpg'
import banner2 from '../../assets/home-banner2.jpg'
import banner3 from '../../assets/home-banner3.jpg'
import banner4 from '../../assets/home-banner4.jpg' */
import darkBanner from '../../assets/home-darkBanner.jpg'
import thumbnail1 from '../../assets/home-thumbnail1.png'
import thumbnail2 from '../../assets/home-thumbnail2.png'
import thumbnail3 from '../../assets/home-thumbnail3.png'
import mapImage from '../../assets/home-map.jpg'
import triangle from '../../assets/triangle.svg'
import mobileBannerMedium from '../../assets/mobileBanner-medium.jpg'
import DarkSection from '../../components/darkSection'
import axios from 'axios'
import {Helmet} from 'react-helmet'

/* const banners = [
	{src: banner4, alt: 'Trains à 140 km/h, les plus rapides du combiné'},
	{src: banner2, alt: 'Spécialiste en température dirigée & messagerie'},
	{src: banner3, alt: "Haut niveau d'expertise dans le domaine ferroviaire"},
	{src: banner1, alt: "Une équipe disponible & à l'écoute de vos besoins"},
	{src: banner4, alt: 'Trains à 140 km/h, les plus rapides du combiné'}
]
const headers = [
  'Trains à 140 km/h', 'les plus rapides du combiné',
  'Spécialiste en température', 'dirigée & messagerie',
  "Haut niveau d'expertise", 'dans le domaine ferroviaire',
  'Une équipe disponible &', "à l'écoute de vos besoins",
  'Trains à 140 km/h', 'les plus rapides du combiné'
] */

const passiveSupported = () => {
  let supported = false

  try {
		var options = Object.defineProperty({}, 'passive', {
	  	get: function () {
	      supported = true
	    }
	  })

	  window.addEventListener('test', options, options)
	  window.removeEventListener('test', options, options)
  } catch (err) {
	  supported = false
  }

  return supported
}

export default class Home extends Component {
  constructor (p) {
    super(p)
    this.state = {scrollTop: 0, currentBanner: 0, showHeader: true, banners: [], headers: []}
  }
  componentDidMount () {
		axios.get('https://froidcombi.fr/api/banners').then((fetchedBanners) => {
			let banners = fetchedBanners.data.map((b) => ({
				src: 'https://froidcombi.fr/api/resource/media/file/' + b.image,
				alt: b.header1 + (b.header2.length > 0 ? ' ' + b.header2 : '')
			}))
			let headers = []
			for (let b of fetchedBanners.data) {
				headers.push(b.header1)
				headers.push(b.header2)
			}
			if (fetchedBanners.data.length > 0) {
				banners.push(banners[0])
				headers.push(headers[0])
				headers.push(headers[1])
			}
      this.setState({banners: banners, headers: headers})
    })

    window.addEventListener('scroll', (e) => {
      this.setState({scrollTop: window.scrollY})
    }, passiveSupported())
  }

  imageSlided (i) {
    this.setState({showHeader: false})
    setTimeout(() => { this.setState({showHeader: true, currentBanner: i}) }, 2000)
  }

  setBanner (ref) {
    if (!ref || this.banner) return
    this.banner = ref
    this.setState({contentPadding: this.banner.clientHeight})
  }

  renderHead () {
    return (
      <Helmet>
        <title>Froidcombi - Opérateur de transport combiné</title>
        <meta name='description' content='Opérateur de transport combiné, spécialiste ferroviaire du transport sous température dirigée et de la messagerie.' />
        <meta property='og:url' content='https://froidcombi.fr' />
        <meta property='og:title' content='Froidcombi - Opérateur de transport combiné' />
        <meta property='og:locale' content='fr_FR' />
        <meta property='og:description' content='Opérateur de transport combiné, spécialiste ferroviaire du transport sous température dirigée et de la messagerie.' />
      </Helmet>
    )
  }

  render () {
    return (
      <div class={style.home}>
        {this.renderHead()}
        <div class={style.sliderContainer}>
          <Slider images={this.state.banners} scrollOffset={this.state.scrollTop} imageSlided={this.imageSlided.bind(this)} />
					<div class={style.container}>
						<h1 class={(this.state.showHeader ? '' : style.hidden)}>{this.state.headers[2 * this.state.currentBanner]}<br />{this.state.headers[2 * this.state.currentBanner + 1]}</h1>
          </div>
        </div>
        <div class={style.mobileImage}>
          <img src={mobileBannerMedium} />
          <h1 class={this.state.showHeader ? '' : ' ' + style.hidden}>{this.state.headers[2 * this.state.currentBanner]}<br />{this.state.headers[2 * this.state.currentBanner + 1]}</h1>
        </div>
        <div class={style.content}>
          <div class={style.lightSection}>
            <div style={{width: '100%', maxWidth: '861px', backgroundSize: 'cover', backgroundPosition: 'center', position: 'absolute', top: 0, left: '50%', transform: 'translateX(-50%)', bottom: 0, backgroundImage: 'url(' + mapImage + ')'}} />
            <div class={style.thumbnails}>
              <div class={style.thumbnail}>
                <Thumbnail image={thumbnail1} onClick={() => { route('/notre-entreprise') }}>
									Découvrez<br />Froidcombi
								</Thumbnail>
              </div>
              <div class={style.thumbnail}>
                <Thumbnail image={thumbnail2} onClick={() => { route('/operateur-de-transport-combine') }}>
									Opérateur de<br />transport combiné
								</Thumbnail>
              </div>
              <div class={style.thumbnail}>
                <Thumbnail image={thumbnail3} onClick={() => { route('/agences') }}>
									Au plus près<br />de nos clients
								</Thumbnail>
              </div>
            </div>
            <img src={triangle} class={style.triangle} />
          </div>
          <DarkSection image={darkBanner} title='Plan de transport' text='Découvrez la carte interactive des terminaux Froidcombi' buttonText='En savoir plus' href='/plan-de-transport' />
        </div>
      </div>
    )
  }
}
