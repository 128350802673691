import { h, Component } from 'preact'
import style from './style'
import Button from '../button'
import { route } from 'preact-router';

export default class DarkSection extends Component {
  render () {
    return (
      <div class={style.darkSection} style={{backgroundImage: 'url(' + this.props.image + ')'}}>
        <h2>{this.props.title}</h2>
        <div class={style.separator} />
        <div class={style.text}>{this.props.text}</div>
        <div class={style.button}>
          <Button text={this.props.buttonText} onClick={() => route(this.props.href)} />
        </div>
      </div>
    )
  }
}
