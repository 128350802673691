import React, {PureComponent} from 'react'
import style from './style'

class Slider extends PureComponent {
  constructor (p) {
    super(p)
    this.state = {currentImage: 0}
  }

  componentDidMount () {
    console.log(style)
    this.t = setTimeout(this.nextImage.bind(this), 6000)
  }

  nextImage () {
    const nextIndex = (this.state.currentImage === this.props.images.length - 1) ? 0 : this.state.currentImage + 1
    this.slideTo(nextIndex)
  }

  componentWillUnmount () {
    clearTimeout(this.t)
  }

  renderImage (image, i) {
    let s = {
      transform: 'translateX(-' + (100 * this.state.currentImage).toString() + '%)'
    }
    if (this.state.currentImage === 0 && !this.state.isManual) {
      s.transition = 'none'
    }

    let srcset = image.src + ' 1x'
    if (image.src2x) {
      srcset += ',' + image.src2x + ' 2x'
    }
    return (
      <div class={style.container} key={i} style={s}>
        <picture>
          <source srcset={srcset} type='image/jpeg' />
          <img class={style.image} src={image.src} alt={image.alt} style={{top: 0, transform: 'translateY(' + (0.5 * this.props.scrollOffset).toString() + 'px)'}}/>
        </picture>
      </div>
    )
  }

  slideTo (i, isManual) {
    clearTimeout(this.t)
    this.setState({currentImage: i, isManual: isManual}, () => {
      if (i === this.props.images.length - 1) {
        setTimeout(() => { this.setState({currentImage: 0}) }, 2000)
      }
    })
    if (this.props.imageSlided) this.props.imageSlided(i)
    this.t = setTimeout(this.nextImage.bind(this), 6000)
  }

  render () {
    let activePoint = (this.state.currentImage === this.props.images.length - 1) ? 0 : this.state.currentImage
    let points = []
    for (let i = 0; i <= this.props.images.length - 2; ++i) {
      points.push(<div class={style.point + ((i === activePoint) ? ' ' + style.active : '')} key={i} onClick={this.slideTo.bind(this, i, true)} />)
    }

    return (
      <div class={style.Slider}>
        {
          this.props.disabled
          ? this.renderImage(this.props.images[0], 0)
          : this.props.images.map(this.renderImage.bind(this))
        }
        {/*<div class={style.points}>
          {points}
        </div>*/}

        {/*
          (!this.props.disabled && this.state.currentImage > 0 && this.state.currentImage < this.props.images.length - 1) && (
            <FontAwesome name='chevron-left' onClick={this.slideTo.bind(this, this.state.currentImage - 1, true)} />
          )
        */}
        {/*
          (!this.props.disabled && !this.props.basketOpen && this.state.currentImage < this.props.images.length - 2) && (
            <FontAwesome name='chevron-right' onClick={this.slideTo.bind(this, this.state.currentImage + 1, true)} />
          )
        */}
      </div>
    )
  }
}

export default Slider
